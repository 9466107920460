import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  static targets = ['input', 'fileList'];

  connect() {
  }

  readURL() {
    this.fileListTarget.innerHTML = '';
    const files = this.inputTarget.files;
    for (let i = 0; i < files.length; i++) {
      this.fileListTarget.innerHTML += `    <div class="w-full border border-downy-300 bg-white rounded-lg px-4 py-3 mb-4">
                                              <div class="flex justify-start gap-4 flex-wrap">
                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      class="flex-grow-0 flex-shrink-0 w-7 h-7 relative"
                                                      preserveAspectRatio="xMidYMid meet">
                                                    <rect width="32" height="32" rx="16" fill="#F3FAFA"></rect>
                                                    <path d="M16.6667 9.33325H12C11.6464 9.33325 11.3073 9.47373 11.0572 9.72378C10.8072 9.97382 10.6667 10.313 10.6667 10.6666V21.3333C10.6667 21.6869 10.8072 22.026 11.0572 22.2761C11.3073 22.5261 11.6464 22.6666 12 22.6666H20C20.3536 22.6666 20.6928 22.5261 20.9428 22.2761C21.1929 22.026 21.3334 21.6869 21.3334 21.3333V13.9999M16.6667 9.33325L21.3334 13.9999M16.6667 9.33325V13.9999H21.3334"
                                                          stroke="#62C6C5" stroke-width="1.33333" stroke-linecap="round"
                                                          stroke-linejoin="round"></path>
                                                </svg>
                                                <div class="w-[calc(100%-80px)]  max-w-[calc(100%-80px)] overflow-hidden">
                                                    <div class="font-medium truncate">${files[i].name}</div>
                                                    <div class="text-gray-500">${(files[i].size / 1048576).toFixed(2) + 'MB'}</div>
                                                </div>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                  xmlns="http://www.w3.org/2000/svg" class="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
                                                  preserveAspectRatio="none">
                                                  <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#62C6C5"></rect>
                                                  <path d="M11.3333 5.5L6.75001 10.0833L4.66667 8" stroke="white" stroke-width="1.66667"
                                                        stroke-linecap="round" stroke-linejoin="round"></path>
                                                  <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#62C6C5"></rect>
                                                </svg>
                                              </div>
                                            </div>`;

    }
  }

}
