import { Controller } from "@hotwired/stimulus";
import { Modal } from 'flowbite';

// Connects to data-controller="modal"
export default class extends Controller {

  static targets = ["modal", "form"];
  static values = { formAction: String };

  connect() {
    this.initModal();
  }

  initModal() {
    this.modal = new Modal(this.modalTarget, {
      backdropClasses:
        'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-[101]',
      onShow: () => {
        this.setFormAction();
      },
    });
  }

  hide() {
    this.modal.hide();
  }

  show() {
    this.modal.show();
  }

  setFormAction() {
    console.log(this.formActionValue);
    this.formTarget.setAttribute("action", this.formActionValue);
  }

  disconnect() {
    this.modal.hide();
    this.modal.destroy();
  }
}