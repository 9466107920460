import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        this.setTopValue();
      }
    
      setTopValue(){
        this.element.style.paddingTop = Math.floor(document.getElementById("header").offsetHeight)-1 + "px";
      }
  
}