import {Controller} from "@hotwired/stimulus";
import {loadStripe} from '@stripe/stripe-js';

export default class extends Controller {
    static targets = ["cardElement", "paymentForm", "paymentToken", "cardErrors", "paymentProcess"];
    static values = {apiKey: String, clientSecret: String, returnUrl: String};

    connect() {
        this.initializeStripe();
    }

    async initializeStripe() {
        // const stripe = await loadStripe(this.apiKeyValue);
        // this.stripe = stripe;
        // let elements = stripe.elements();
        // let cardElement = elements.create('card');
        //
        // // Add an instance of the card Element into the `card-element` div.
        // cardElement.mount(this.cardElementTarget);
        //
        // // Handle form creating payment method and submitting to server
        // let form = this.paymentFormTarget;
        // let paymentTokenField = this.paymentTokenTarget;
        // let errorElement = this.cardErrorsTarget;
        // let submitButton = this.paymentProcessTarget;
        //
        // submitButton.addEventListener("click", function () {
        //     stripe.createToken(cardElement).then(function (result) {
        //         if (result.error) {
        //             // Show Stripe error in payment form
        //             errorElement.textContent = result.error.message;
        //         } else {
        //             // Token represents the card and can be sent to your server.
        //             paymentTokenField.value = result.token.id;
        //             form.submit();
        //         }
        //     });
        // });

        // ======================

        // console.log('this.apiKeyValue', this.apiKeyValue);
        // console.log('this.clientSecretValue', this.clientSecretValue);
        // console.log('this.returnUrlValue', this.returnUrlValue);

        const stripe = await loadStripe(this.apiKeyValue);
        const options = {
            // clientSecret: '{{client_secret}}',
            clientSecret: this.clientSecretValue,
            currency: 'usd',
            appearance: {/*...*/},
        };

        // Set up Stripe.js and Elements using the SetupIntent's client secret
        const elements = stripe.elements(options);

        // Create and mount the Payment Element
        const paymentElement = elements.create('payment');
        paymentElement.mount('#payment-element');


        //  ----- checkout.js -----
        // const return_url = '{{ request.scheme }}://{{ request.get_host }}{% url "finance:client_pm_status" %}';
        const return_url = this.returnUrlValue;
        const form = document.getElementById('payment-form');

        form.addEventListener('submit', async (event) => {
            event.preventDefault();

            const {error} = await stripe.confirmSetup({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                    return_url: return_url,
                }
            });

            if (error) {
                // This point will only be reached if there is an immediate error when
                // confirming the payment. Show error to your customer (for example, payment
                // details incomplete)
                const messageContainer = document.querySelector('#error-message');
                messageContainer.textContent = error.message;
            } else {
                // Your customer will be redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer will be redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
            }
        });
    }

    disconnect() {
        this.element.remove();
    }


}