import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["button"];

    connect() {
    }

    scroll(e) {
        e.preventDefault();

        const targetId = e.target.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            const offset = targetElement.getBoundingClientRect().top + window.scrollY - 108;
            window.scrollTo({
                top: offset,
                behavior: 'smooth'
            });
        }
    }

    disconnect() {
        this.element.remove();
    }

}
