import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
static targets = ["list", "userListItem"];
  connect() { 
    document.addEventListener('parent:frame-render', this.handleParentFrameLoaded);
    this.scrollToDown();
    
  }

  handleParentFrameLoaded = () => {
    const _this = this;
    _this.scrollToDown();
  };

  scrollToDown() {
      this.listTarget.scrollTop = this.listTarget.scrollHeight;
  }
}
