import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "hamburger", "close", "overlay"];
  static values = {isOpen: {type: Boolean, default: true}};
 
  connect() {       
    if (window.innerWidth < 1024){
      this.isOpen = !this.isOpen;
    }
  }
  
  layout() {
    if (window.innerWidth> 1024){
      this.overlayTarget.classList.add("opacity-0");
      this.overlayTarget.classList.remove("opacity-100"); 

    }
    else {
      this.hide();
    }
  }
  animateHide(){
    setTimeout(() => {
        this.hide();
      }, "300");
  
  }
  hide(){    
    this.containerTarget.classList.add("-translate-x-full");
    this.containerTarget.classList.remove("translate-none"); 
    this.overlayTarget.classList.add("opacity-0", "hidden");    
  }

  show(){
    this.containerTarget.classList.add("translate-none");
    this.containerTarget.classList.remove("-translate-x-full"); 
    this.overlayTarget.classList.remove("opacity-0", "hidden");    
    this.overlayTarget.classList.add("opacity-100");    
  }
  close(){
    this.hide();
  }
}
