import { Controller } from "@hotwired/stimulus";

export default class extends Controller {   
    static values = {sender: {type: String, default: ''}};

      connect() {
        this.addMessageClass();
        document.addEventListener('parent:frame-render', this.handleParentFrameLoaded);
      }  

      handleParentFrameLoaded = () => {
        const _this = this;
        _this.addMessageClass();
      };
    
      addMessageClass() {
        let currentUser = document.getElementById("chat").getAttribute("data-current-user-value");
        let sender = this.senderValue;
        if (sender == currentUser) {
            this.element.classList.add("message-own");
        } 
        else if (sender == '') {
          this.element.classList.add("message-system");
        }
        else {
            this.element.classList.add("message-recieved");
        }    
      }

      
}