import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

    static targets = ["content"];

    connect() {
      this.setHeight(); 
    }

    setHeight(){
      let maxHeight = 0;
      this.contentTargets.forEach(element=> {
        const elementHeight = element.offsetHeight;
        if (elementHeight > maxHeight) {
          maxHeight = elementHeight;
        }
      });
      this.contentTargets.forEach(element=> {
        element.style.height = `${maxHeight}px`;
      });
    }
}