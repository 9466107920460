// This is the main website component

// This is the scss entry file
import "../styles/crelancer.scss";

import "@hotwired/turbo";

import 'flowbite';

import "@stripe/stripe-js";

import "../controllers";

import 'swiper/css/bundle';

// We can import other JS file as we like
// import "../components/saide.js";
// require('webpack-jquery-ui/autocomplete');


window.document.addEventListener("DOMContentLoaded", function () {
  window.console.log("dom ready 1");
});

document.addEventListener('turbo:load', function () { // new
  console.log('turbo:load');
});

/*dispatch event for chat*/
let chatMessages = document.querySelector('#chat-messages');
if (chatMessages !== null) {
chatMessages.addEventListener('turbo:frame-render', () => {
  const event = new Event('parent:frame-render');
  document.dispatchEvent(event);
});
}

let chatdealsList = document.querySelector('#my-deals-list');
if (chatdealsList !== null) {
  chatdealsList.addEventListener('turbo:frame-load', () => {
  const event = new Event('parent:frame-load');
  document.dispatchEvent(event);
});
}

