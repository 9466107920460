import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["banner"];

  connect() {    
    this.showBanner();
    //localStorage.clear();
  }

  showBanner() {
    const isBannerClicked = localStorage.getItem("CookieBannerClicked");
    if (isBannerClicked === null) {
      this.element.classList.remove("hidden");
    }
  }

  hideBanner() {
    this.element.classList.add("hidden");
  }

  setLocalStorageItem() {
    localStorage.setItem("CookieBannerClicked", true);
    this.hideBanner();
  }

  disconnect() {
    this.element.remove();
  }
}
