import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["inputFile", "error", "maxFileLength"];
  static values = {maxFileLength: {type: Number, default: 10}, setAttributes: {type: Boolean, default: false}};

  initialize() {
    if (this.setAttributesValue){
      this.setAttributes();
    }    
  }

  connect() {
   this.buttonClicked = false;
   console.log(this.setAttributesValue);
  } 

  setAttributes() {
    this.element.querySelector('input[type="file"]').setAttribute("data-file-upload-validation-target", "inputFile");   
  }

  validateFileLength(event) {    
    const files = this.inputFileTarget.files;
    if (files.length > this.maxFileLengthValue) {
      event.preventDefault();
      this.buttonClicked = false;
      this.maxFileLengthTarget.innerHTML = this.maxFileLengthValue;
      this.errorTarget.classList.remove("hidden");
    }
    else {
      this.DisableFormSubmit(event);
    }    
  }

  DisableFormSubmit(event) {
    if (!this.buttonClicked) {
        this.buttonClicked = true;
      } else {
        event.preventDefault();
      }
}

  disconnect() {
    this.element.remove();
  }
}