import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["filteredElement", "button", "emptySearch"];
    connect() {
    }

    showAll(e) {
        this.removeActiveClass();
        this.filteredElementTargets.forEach(element => {
            element.classList.remove('hidden');
            element.classList.add('block');
        });
        e.target.classList.add("border-downy-300", "text-downy-300");
        e.target.classList.remove("border-transparent");
        this.hideEmptySearchResult() ;
       
    }

    showItemsByCategory(category, e) {
        this.removeActiveClass();
        this.filteredElementTargets.forEach(element => {
            if (!element.dataset[category]) {
                element.classList.add('hidden');
            } else {
                element.classList.remove('hidden');
            }
        });
        e.target.classList.add("border-downy-300", "text-downy-300");
        e.target.classList.remove("border-transparent");
        const allHidden = this.filteredElementTargets.every(element => element.classList.contains('hidden'));
        if (allHidden) {
            this.showEmptySearchResult();
        } else {
            this.hideEmptySearchResult();
        }
        
    }

    showDrafts(e) {
        this.removeActiveClass();
        this.showItemsByCategory('drafts', e);
    }

    showPublics(e) {
        this.removeActiveClass();
        this.showItemsByCategory('public', e);
    }

    showProgresses(e) {
        this.removeActiveClass();
        this.showItemsByCategory('progress', e);
    }

    showCanceled(e) {
        this.removeActiveClass();
        this.showItemsByCategory('canceled', e);
    }

    removeActiveClass() {
        this.buttonTargets.forEach(button => {
            button.classList.remove("border-downy-300", "text-downy-300");
            button.classList.add("border-transparent");
        });
    }

    showEmptySearchResult() {
        this.emptySearchTarget.classList.remove("hidden");
    }
    hideEmptySearchResult() {
        this.emptySearchTarget.classList.add("hidden");
    }

}