import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["emptyForm", "formset"];
  connect() {
  this.checkDeleted(); 
  }

  duplicate() {
    let totalFormsElement = document.querySelector('[id$="TOTAL_FORMS"]');
    let formIndex =  totalFormsElement.getAttribute('value');
    formIndex = parseInt(formIndex);

    let duplicateForm = this.emptyFormTarget.cloneNode(true);
    duplicateForm.classList.remove("hidden");

    let newContent = duplicateForm.innerHTML.replace(/__prefix__/g, formIndex); 
    
    let newForm = document.createElement('div');
    newForm.classList.add("new-form");
    newForm.innerHTML = newContent;    

    this.formsetTarget.append(newForm);    

    totalFormsElement.setAttribute( "value", formIndex+1 );
  }

  hideForm(event) {
    const form = event.target.closest('.new-form');
    const checkbox = event.target.closest('.new-form').querySelector('.checkboxinput');
    checkbox.checked = true;
    form.classList.add("hidden");

  }

  checkDeleted() {
    let checkboxes = document.querySelectorAll(".checkboxinput");
    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        let closestNewForm = checkbox.closest(".new-form");
        let closestFormsetDivider = checkbox.closest(".formset-divider");

        if (closestNewForm) {
          closestNewForm.classList.add("hidden");
        }

        if (closestFormsetDivider) {
          closestFormsetDivider.classList.add("hidden");
        }
      }
    });
  }

}