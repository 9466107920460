import { application } from "./application";

import AnchorMovementController from "./anchor_movement_controller";
application.register("anchor-movement", AnchorMovementController);

import AsideController from "./aside_controller";
application.register("aside", AsideController);

import FileUploadController from "./file_upload_controller";
application.register("file-upload", FileUploadController);

import AutoheightController from "./autoheight_controller";
application.register("autoheight", AutoheightController);

import SliderController from "./slider_controller";
application.register("slider", SliderController);

import DropdownController from "./dropdown_controller";
application.register("dropdown", DropdownController);

import PaddingValueController from "./padding_value_controller";
application.register("padding-value", PaddingValueController);

import DuplicateFormController from "./duplicate_form_controller";
application.register("duplicate-form", DuplicateFormController);

import FilterFormController from "./filter_form_controller";
application.register("filter-form", FilterFormController);

import FilterDealsController from "./filter_deals_controller";
application.register("filter-deals", FilterDealsController);

import FilterJobsController from "./filter_jobs_controller";
application.register("filter-jobs", FilterJobsController);

import ResponsiveFilterController from "./responsive_filter_controller";
application.register("responsive-filter", ResponsiveFilterController);

import CollapseController from "./collapse_controller";
application.register("collapse", CollapseController);

import GalleryController from "./gallery_controller";
application.register("gallery", GalleryController);

import ReadMoreController from "./read_more_controller";
application.register("read-more", ReadMoreController);

import NavbarController from "./navbar_controller";
application.register("navbar", NavbarController);

import PopupController from "./popup_controller";
application.register("popup", PopupController);

import ChatController from "./chat/chat_controller";
application.register("chat", ChatController);

import ScrollToDownController from "./scroll_to_down_controller";
application.register("scroll-to-down", ScrollToDownController);

import ChatMessageController from "./chat/chat_message_controller";
application.register("chat-message", ChatMessageController);

import StripeController from "./stripe_controller";
application.register("stripe", StripeController);

import DisableButtonController from "./disable_button_controller";
application.register("disable-button", DisableButtonController);

import FileUploadValidationController from "./file_upload_validation_controller";
application.register("file-upload-validation", FileUploadValidationController);

import CookieBannerController from "./cookie_banner_controller";
application.register("cookie-banner", CookieBannerController);

import ReviewModalController from "./review_modal_controller";
application.register("review-modal", ReviewModalController);
