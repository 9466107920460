import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["submit", "clear", "searchIn", "searchOut", "checkbox", "priceMin", "priceMax", "state", "skills", "skillTag", "searchResult"];
    static values = {type: {type: String, default: ""}};
    initialize() {
      this.setAttributes();
    }
    
    connect() {
      
    }

    setAttributes() {
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach(checkbox =>{
        checkbox.setAttribute("data-filter-form-target", "checkbox");
      });

      if (this.typeValue == "crelancers") {
        const priceMin = document.querySelector('input[name="rate_min"]');
        priceMin.setAttribute("data-filter-form-target", "priceMin");
  
        const priceMax = document.querySelector('input[name="rate_max"]');
        priceMax.setAttribute("data-filter-form-target", "priceMax");
      }

      if (this.typeValue == "jobs") {
        const priceMin = document.querySelector('input[name="budget_min"]');
        priceMin.setAttribute("data-filter-form-target", "priceMin");
  
        const priceMax = document.querySelector('input[name="budget_max"]');
        priceMax.setAttribute("data-filter-form-target", "priceMax");
      }

      const state = document.getElementById('id_state');
      state.setAttribute("data-filter-form-target", "state");

      const skills = document.getElementById("id_skills_tags_input");
      skills.setAttribute("data-filter-form-target", "skills");     
    }

    submitClick() {
      this.submitTarget.click();  
    }
    
    updateForm(){      
      this.updateSearchInput();      
      this.submitClick();     

    } 

    clearForm(){
      this.clearSearchInput();
      this.submitClick();
    }

    updateSearchInput(){
      this.searchOutTarget.value = this.searchInTarget.value; 
    }
    
    clearSearchInput(){
      this.searchInTarget.value = '';
      this.searchOutTarget.value = this.searchInTarget.value;
    }

    clearAll() {
      this.checkboxTargets.forEach(checkbox => {
        checkbox.checked = false;
      });

      this.priceMinTarget.value = '';
      this.priceMaxTarget.value = '';
      this.stateTarget.value = '';
      this.skillsTarget.value = '';
      this.searchInTarget.value = '';
      this.searchOutTarget.value = '';
      document.querySelectorAll(".tag").forEach(tag => {
        tag.remove();
      });

      this.submitClick();
    }  
    
    setUrl() {
      const frame = this.searchResultTarget;
      frame.addEventListener("turbo:frame-load", this.onFrameLoad.bind(this));
    }

    onFrameLoad(event) {
      const url = event.target.getAttribute("src");
      window.history.replaceState({}, "", url);
    }
}