import { Application } from "@hotwired/stimulus";

import { Alert } from "tailwindcss-stimulus-components";

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus   = application;

window.Stimulus.register('alert', Alert);

export { application };