import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["mobileWrap", "desktopWrap", "filter"];
    
    connect() {
      this.moveFilter();
      }
    
    moveFilter() {
        if (window.innerWidth < 1024){
            this.mobileWrapTarget.append(this.filterTarget);
            this.mobileWrapTarget.classList.remove("hidden");           
        }
        else {
            this.desktopWrapTarget.append(this.filterTarget); 
            this.mobileWrapTarget.classList.add("hidden");
            this.mobileWrapTarget.classList.add("translate-y-[200%]"); 
            document.body.classList.remove("overflow-hidden");   
        }
        
    }

    showFilter() {
        this.mobileWrapTarget.classList.remove("translate-y-[200%]");
        document.body.classList.add("overflow-hidden");
    }

    hideFilter() {
        this.mobileWrapTarget.classList.add("translate-y-[200%]");
        document.body.classList.remove("overflow-hidden");
    }

}