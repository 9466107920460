import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
   console.log("popup connected");
   this.show();

  }

  show(){
    setTimeout(() => {
        this.element.classList.remove("hidden");
        this.element.classList.add("block");
      }, "5000");
  }

  hide(){
    this.element.classList.remove("block");
    this.element.classList.add("hidden");
    this.element.remove();
  }

  disconnect() {
    this.element.remove();
  }
}