import ReadMore from 'stimulus-read-more';

export default class extends ReadMore {
    static targets = ["button"];
    static values = {maxLength: {type: Number, default: 150}};
  connect() {
    super.connect();
    this.hideButton();
  }

  hideButton() {
    if (this.contentTarget.innerHTML.length < this.maxLengthValue) {
        this.buttonTarget.classList.add("hidden");
    }
  }
  
}