import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["triggerEl", "targetEl", "arrowDown", "arrowUp"];
  static values = {isOpen: {type: Boolean, default: false}, openOnLoad:{type: Boolean, default: false}};
  connect() {
    this.setDefaultState();
  }

  setDefaultState() {
    if(this.openOnLoadValue){
      this.show;      
    }
    else {
      this.hide();   
    }
  }

  toggle() {
    if (this.isOpenValue) {
        this.hide();
        this.isOpenValue = !this.isOpenValue;
    }
    else {
        this.show();
        this.isOpenValue = !this.isOpenValue;
    }
  }

  hide() {
    this.element.style.height = this.triggerElTarget.offsetHeight + "px";   
    this.arrowDownTarget.classList.add("hidden");
    this.arrowUpTarget.classList.remove("hidden");
    
  }

  show(){
    this.element.style.height = this.targetElTarget.offsetHeight + this.triggerElTarget.offsetHeight + "px";
    this.arrowDownTarget.classList.remove("hidden");
    this.arrowUpTarget.classList.add("hidden");
    
  }
}