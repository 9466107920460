import { Controller } from "@hotwired/stimulus";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";


export default class extends Controller {
    static targets = ["image"];
  connect() {
    this.setImageSize();  
    this.initGallery();    
  }

  initGallery() {
    this.lightbox = new PhotoSwipeLightbox({
        gallery: this.element,
        children: "a",
        pswpModule: () => import("photoswipe")
      });
      this.lightbox.init();
  }

  setImageSize() {
    this.imageTargets.forEach(image => {      
      const fullsizeSrc = image.getAttribute('data-fullsize-src');
      const img = new Image();
      img.src = fullsizeSrc;
      img.onload = function() {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        image.parentNode.setAttribute("data-pswp-width", width);
        image.parentNode.setAttribute("data-pswp-height", height);
      };
    });    
  }
  
}